import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { IMasonryGalleryImage } from 'ngx-masonry-gallery';
import { ContactService } from '../contact.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  formSubmitted: boolean = false;

  contactForm: FormGroup = new FormGroup({
    'email': new FormControl('', [
      Validators.required
    ]),
    'subject': new FormControl('', [
      Validators.required
    ]),
    'message': new FormControl('', [
      Validators.required
    ])
  });

  private urls: string[] = [
    "assets/images/Office_1.jpeg",
    "assets/images/Office_2.jpeg",
    "assets/images/Office_3.jpeg",
    "assets/images/Office_4.jpeg",
    "assets/images/Office_5.jpeg",
    "assets/images/Office_6.jpeg",
    "assets/images/Office_7.jpeg",
    "assets/images/Office_7b.jpeg",
    "assets/images/Office_8.jpeg",
    "assets/images/Office_9.jpeg",
    "assets/images/Office_10.JPG",
    "assets/images/Office_11.jpeg",
    "assets/images/Office_12.jpg",
    "assets/images/Office_13.jpg",
    "assets/images/Office_14.jpg",
    "assets/images/Office_15.JPG",
    "assets/images/Office_16.JPG",
    "assets/images/Office_17.JPG",
    "assets/images/Office_18.jpg",
    "assets/images/Office_19.jpg",
    "assets/images/Office_19b.jpeg",
    "assets/images/Office_20.PNG",
    "assets/images/Office_21.jpg",
    "assets/images/Office_22.jpg",
    "assets/images/Office_23.jpg",
    "assets/images/Office_24.jpg",
    "assets/images/Office_25.jpg",
    "assets/images/Office_25b.jpg",
    "assets/images/Office_26.jpg",
    "assets/images/Outdoor.jpg",
    "assets/images/Outdoor_1.PNG",
    "assets/images/Outdoor_2.jpeg",
    "assets/images/Outdoor_3.jpeg",
    "assets/images/Outdoor_4.jpeg",
    "assets/images/Outdoor_5.jpg",
    "assets/images/Outdoor_6.jpg",
    "assets/images/Party.jpeg",
    "assets/images/Party_0.jpeg",
    "assets/images/Party_1.jpeg",
    "assets/images/Party_2.jpeg",
    "assets/images/Party_3.jpeg",
    "assets/images/Party_4.jpeg",
    "assets/images/Party_4b.jpeg",
    "assets/images/Party_4c.jpeg",
    "assets/images/Party_5.jpeg",
    "assets/images/Party_6.jpeg",
    "assets/images/Party_7.jpeg",
    "assets/images/Party_8.jpeg",
    "assets/images/Party_8b.jpg",
    "assets/images/Party_9.jpeg",
    "assets/images/Party_10.jpeg",
    "assets/images/Party_11.jpeg",
    "assets/images/Party_12.jpeg",
    "assets/images/Party_13.jpg",
    "assets/images/Party_13b.JPG",
    "assets/images/Party_14.JPG",
    "assets/images/Party_15.JPG",
    "assets/images/Party_16.jpeg",
    "assets/images/Party_16b.jpg",
    "assets/images/Party_16c.jpeg",
    "assets/images/Party_16d.jpg",
    "assets/images/Party_17.jpg",
    "assets/images/Party_18.jpg",
    "assets/images/Party_19.jpg",
    "assets/images/Party_20.jpg",
    "assets/images/Party_21.jpg",
    "assets/images/Party_22.jpg",
    "assets/images/Party_23.jpg",
    "assets/images/Party_23b.jpeg",
    "assets/images/Party_23b.jpg",
    "assets/images/Party_23c.JPG",
    "assets/images/Party_24.jpg",
    "assets/images/Party_25.jpg",
    "assets/images/Party_26.jpg",
    "assets/images/Party_27.jpg",
    "assets/images/Warning_1.jpg",
    "assets/images/Warning_2.jpg",
    "assets/images/Warning_3.jpg",
    "assets/images/Warning_4.jpg",
    "assets/images/X_New_Job.jpg",
  ];

  public get images(): IMasonryGalleryImage[] {
    return this.urls.map(m => <IMasonryGalleryImage>{
        imageUrl: m
     });
  }

  constructor(private contactService: ContactService) { }

  ngOnInit() {
  }

  players = [
    {
      name: "Anne",
      nick: "Rad Donna"
    },
    {
      name: "john",
      nick: "JSMDB"
    },
    {
      nick: 'mithrim',
      name: 'daniel'
    },
    {
      nick: "die chefin",
      name: "Julia",
    },
    {
      name: "jenny",
      nick: "jenny boom boom"
    },
    {
      name: "julia",
      nick: "mini mini"
    },
    {
      name: "julia",
      nick: "cordi"
    },
    {
      nick: "TV-FEE-LISI",
      name: "Lisa"
    },
    {
      name: "mira",
      nick: "mirag1337"
    },
    {
      name: "betty",
      nick: "betty beer"
    },
    {
      name: "lisa",
      nick: "limu"
    },
    {
      name: "marica",
      nick: "village"
    },
    {
      name: "sohpia",
      nick: "newbie"
    },
    {
      name: "david",
      nick: "the und3rd0g jr."
    },
    {
      name: "Christian",
      nick: "Meller"
    },
    {
      name: "Magnus",
      nick: "dabu"
    },
    {
      name: "henriette",
      nick: "interim"
    },
    {
      name: "lara",
      nick: "ms croft"
    }
  ]

  results = [
    {
      id: 4,
      date: "05/04/2020",
      team: "../../assets/games/csgo-sm.png",
      opponent: "../../assets/teams/nologo.png",
      result: "12-16",
      ending: "loss",
      league: "ESL Challenger Series Cup #402 Europe",
      link: "https://play.eslgaming.com/counterstrike/csgo/csgo/challenger/5on5-series-europe/cup-402/match/38827944/"
    },
    {
      id: 3,
      date: "29/03/2020",
      team: "../../assets/games/csgo-sm.png",
      opponent: "../../assets/teams/onlineheroes.png",
      result: "11-16",
      ending: "loss",
      league: "ESL Challenger Series Cup #401 Europe",
      link: "https://play.eslgaming.com/counterstrike/csgo/csgo/challenger/5on5-series-europe/cup-401/match/38691242/"
    },
    {
      id: 2,
      date: "22/03/2020",
      team: "../../assets/games/csgo-sm.png",
      opponent: "../../assets/teams/nologo.png",
      result: "4-16",
      ending: "loss",
      league: "ESL Challenger Series Cup #400 Europe",
      link: "https://play.eslgaming.com/counterstrike/csgo/csgo/challenger/5on5-series-europe/cup-400/match/38601545/"
    },
    {
      id: 1,
      date: "22/03/2020",
      team: "../../assets/games/csgo-sm.png",
      opponent: "../../assets/teams/exceptionalmindset.png",
      result: "16-3",
      ending: "win",
      league: "ESL Challenger Series Cup #400 Europe",
      link: "https://play.eslgaming.com/counterstrike/csgo/csgo/challenger/5on5-series-europe/cup-400/match/38601492/"
    },
  ]

  nexttournaments = [
    {
      id: 1,
      date: "19/04/2020",
      team: "../../assets/games/csgo-sm.png",
      league: "../../assets/leagues/esl.png",
      name: "Challenger Series Cup #404 Europe",
      link: "https://play.eslgaming.com/counterstrike/csgo/csgo/challenger/5on5-series-europe/cup-404/"
    }
  ]

  videos = [
    {
      id: 1,
      url: 'W2BDrD7Hw7k',
    },
    {
      id: 2,
      url: 'OR0tawKyoXc',
    },
    {
      id: 3,
      url: 'cyiGCoUhsBg',
    },
    {
      id: 4,
      url: 'QiSuxZcJEi4',
    },
    {
      id: 5,
      url: 'd9RvmxHJVCA',
    },
    {
      id: 6,
      url: 'vfqLjJlt8qY',
    },
  ]

  onSubmit() {
    if(!this.contactForm.valid) {
      return false;
    }

    this.contactService.postContact(this.contactForm.value).subscribe(() => {
      this.formSubmitted = true;
    });

    return false;
  }

}
