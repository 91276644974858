import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private httpClient: HttpClient) { }

  postContact(body: { name: string, email: string, message: string, telephone: string,  }) {
    return this.httpClient.post('https://europe-west1-brandtdrakebrockman-feae5.cloudfunctions.net/bashelorContact', body, { headers: { 'Authorization' : '.kl%wTG{UYEuBE)/<s7fYo3p{JW01FIKQG,kZr9'}} );
  }
}
